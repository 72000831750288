import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import store from "@/store/index.js";
import { format, parseISO } from "date-fns";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";

export async function generatePdf(empresa, ordem_servico_id) {
  const ordem_servico = await fetchOrdemServico(ordem_servico_id);
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.usuario.name;

    let logo = {
      image: empresa.logo64,
      width: 150,
      alignment: "center",
      margin: [0, 0, 0, 25],
    };

    let dados = [
      {
        // margin: [left, top, right, bottom]
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: i18n.tc("global.cliente").toUpperCase(),
                    alignment: "left",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    stack: ordem_servico.cliente_id
                      ? [
                        {
                          text:
                            ordem_servico.cliente_nome +
                            " - " +
                            ordem_servico.cliente_doc,
                          fontSize: 8,
                        },
                        {
                          text: ordem_servico.cliente_endereco
                            ? `${ordem_servico.cliente_endereco}, ${ordem_servico.cliente_cidade}`
                            : "",
                          fontSize: 8,
                        },
                        {
                          text: ordem_servico.cliente_documento,
                          fontSize: 8,
                        },
                        {
                          text: ordem_servico.numero_garantia ? 'Garantía Nº.: ' + ordem_servico.numero_garantia : '',
                          fontSize: 8,
                        },
                        {
                          text: ordem_servico.numero_garantia ? 'Piscina Nº.: ' + ordem_servico.numero_garantia : 'Piscina Nº.: ',
                          fontSize: 8,
                        },
                      ]
                      : [],
                  },
                ],
              },
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: `${i18n
                      .tc("global.ordensServicos")
                      .toUpperCase()} Nº.: ${ordem_servico.id}`,
                    alignment: "right",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text: i18n.tc("global.criado").toUpperCase() + " : " + format(
                      parseISO(ordem_servico.data_criada),
                      "dd/MM/yyyy"
                    ) + ` - ${i18n
                      .tc("global.inicio").toUpperCase()} : ${format(
                        parseISO(ordem_servico.previsao_inicio),
                        "dd/MM/yyyy"
                      )}` + ` - ${i18n
                        .tc("global.fim").toUpperCase()} : ${format(
                          parseISO(ordem_servico.previsao_fim),
                          "dd/MM/yyyy"
                        )}`,
                    alignment: "right",
                    fontSize: 8,
                  },
                  {
                    text: ordem_servico.vendedor_nome ? `${i18n.tc("global.vendedor").toUpperCase()} : ${ordem_servico.vendedor_nome}` : "",
                    alignment: "right",
                    fontSize: 8,
                  },
                  {
                    text: ordem_servico.fornecedor_nome ? `${i18n.tc("global.fornecedor").toUpperCase()} : ${ordem_servico.fornecedor_nome}` : "",
                    alignment: "right",
                    fontSize: 8,
                  }
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          // vLineWidth: function(i, node) {
          //   return i === 0 || i === node.table.widths.length ? 0 : 1;
          // },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      },
    ];

    let tabela_items = {
      table: {
        widths: ["*", "auto", "auto"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: i18n.tc("global.produto").toUpperCase() + "(s)",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text:
                i18n.tc("global.fornecedor").toUpperCase() +
                " " +
                i18n.tc("global.servico").toUpperCase(),
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            // {
            //   text: `${i18n.tc("global.preco").toUpperCase()} ${i18n
            //     .tc("global.servico")
            //     .toUpperCase()} (${ordem_servico.moeda_sigla})`,
            //   alignment: "center",
            //   fontSize: 8,
            //   fillColor: "#CFD8DC",
            //   // color: "#FFFFFF",
            // },
            {
              text: "QTDE",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },

            // {
            //   text: `${i18n.tc("global.preco").toUpperCase()} (${
            //     ordem_servico.moeda_sigla
            //   })`,
            //   fontSize: 8,
            //   fillColor: "#CFD8DC",
            //   // color: "#FFFFFF",
            // },
            // {
            //   text: "SUBTOTAL",
            //   fontSize: 8,
            //   fillColor: "#CFD8DC",
            //   // color: "#FFFFFF",
            // },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    // let subtotal_geral = 0;
    // let desconto_geral = 0;
    // let total_servicos = 0;

    let produtos_items = [];
    ordem_servico.produtos.forEach((produto) => {
      // total_servicos += Number(produto.preco_servico);
      if (produto.tipo == "ITEM") {
        produto.produtos_kit = [];
        produtos_items.push(produto);
      }
    });
    ordem_servico.produtos.forEach((produto) => {
      if (produto.tipo == "SUB-ITEM") {
        let index = produtos_items.findIndex((produto_array) => {
          return produto_array.id === produto.produto_pai_id;
        });
        produto.index_produto = index;

        produtos_items[index].produtos_kit.push(produto);
      }
    });

    produtos_items.forEach((produto) => {
      if (produto.produtos_kit.length) {
        produto.kit = true;
      }
    });

    produtos_items.forEach((item) => {
      let obj = { ...item };

      // let subtotal = Number(obj.qtde) * Number(obj.preco);
      // subtotal_geral += subtotal;

      // desconto_geral += obj.desconto ? obj.desconto : 0;
      // if (ordem_servico.moeda_sigla == "G$") {
      //   obj.preco = Vue.filter("guarani")(Number(obj.preco));
      //   obj.subtotal = Vue.filter("guarani")(Number(subtotal));
      //   obj.preco_servico = Vue.filter("guarani")(Number(obj.preco_servico));
      // } else {
      //   obj.preco = Vue.filter("currency")(Number(obj.preco));
      //   obj.subtotal = Vue.filter("currency")(Number(subtotal));
      // }

      let bodyLineFontSize = 8;

      let bodyLine = [
        {
          text: obj.descricao,
          bold: obj.kit ? true : false,
          fontSize: bodyLineFontSize,
        },
        {
          text: obj.fornecedor_nome,
          fontSize: bodyLineFontSize,
          bold: obj.kit ? true : false,
          alignment: "center",
        },
        // {
        //   text: obj.preco_servico,
        //   fontSize: bodyLineFontSize,
        //   bold: obj.kit ? true : false,
        //   alignment: "center",
        // },
        {
          text: obj.qtde,
          bold: obj.kit ? true : false,
          fontSize: bodyLineFontSize,
          alignment: "center",
        },
        // {
        //   text: obj.preco,
        //   bold: obj.kit ? true : false,
        //   fontSize: bodyLineFontSize,
        //   alignment: "right",
        // },
        // {
        //   text: obj.subtotal,
        //   bold: obj.kit ? true : false,
        //   fontSize: bodyLineFontSize,
        //   alignment: "right",
        // },
      ];

      tabela_items.table.body.push(bodyLine);
      if (obj.kit) {
        obj.produtos_kit.forEach((item) => {
          let obj_kit = { ...item };
          let bodyLineKit = [
            {
              text: "|__   " + obj_kit.descricao,

              fontSize: bodyLineFontSize,
            },
            {
              text: obj_kit.fornecedor_nome,
              fontSize: bodyLineFontSize,

              alignment: "center",
            },
            // {
            //   text: obj_kit.preco_servico,
            //   fontSize: bodyLineFontSize,
            //   alignment: "center",
            // },
            {
              text: obj_kit.qtde,
              fontSize: bodyLineFontSize,
              alignment: "center",
            },
            // {
            //   text: obj_kit.preco + "*",
            //   fontSize: bodyLineFontSize,
            //   alignment: "right",
            // },
            // {
            //   text: obj_kit.subtotal,
            //   fontSize: bodyLineFontSize,
            //   alignment: "right",
            // },
          ];
          tabela_items.table.body.push(bodyLineKit);
        });
      }
    });

    // let legenda = {
    //   stack: [
    //     {
    //       text: i18n.tc("global.valoresMarcadosCom"),
    //       margin: [0, 3, 0, 0],
    //       fontSize: 7,
    //       alignment: "right",
    //     },
    //   ],
    // };

    // let total_geral = subtotal_geral - desconto_geral;

    // if (ordem_servico.moeda_sigla == "G$") {
    //   subtotal_geral = Vue.filter("guarani")(Number(subtotal_geral));
    //   desconto_geral = Vue.filter("guarani")(Number(desconto_geral));
    //   total_geral = Vue.filter("guarani")(Number(total_geral));
    //   total_servicos = Vue.filter("guarani")(Number(total_servicos));
    // } else {
    //   subtotal_geral = Vue.filter("currency")(Number(subtotal_geral));
    //   desconto_geral = Vue.filter("currency")(Number(desconto_geral));
    //   total_geral = Vue.filter("currency")(Number(total_geral));
    //   total_servicos = Vue.filter("currency")(Number(total_servicos));
    // }

    // let tabela_total = {
    //   // margin: [left, top, right, bottom]
    //   margin: [0, 20, 0, 0],
    //   columns: [
    //     { width: "*", text: "" },
    //     {
    //       width: "auto",
    //       table: {
    //         widths: [75, 60],
    //         body: [
    //           [
    //             {
    //               text: "TOTAL " + i18n.tc("global.servico", 2).toUpperCase(),
    //               alignment: "right",
    //               fontSize: 8,
    //               fillColor: "#CFD8DC",
    //             },
    //             {
    //               text: total_servicos,
    //               alignment: "right",
    //               fontSize: 8,
    //             },
    //           ],
    //           [
    //             {
    //               text: "SUBTOTAL",
    //               alignment: "right",
    //               fontSize: 8,
    //               fillColor: "#CFD8DC",
    //             },
    //             {
    //               text: subtotal_geral,
    //               alignment: "right",
    //               fontSize: 8,
    //             },
    //           ],
    //           [
    //             {
    //               text: i18n.tc("global.desconto").toUpperCase(),
    //               alignment: "right",
    //               fontSize: 8,
    //               fillColor: "#CFD8DC",
    //             },
    //             {
    //               text: desconto_geral,
    //               alignment: "right",
    //               fontSize: 8,
    //             },
    //           ],
    //           [
    //             {
    //               text: i18n.tc("global.total").toUpperCase(),
    //               alignment: "right",
    //               fontSize: 8,
    //               fillColor: "#CFD8DC",
    //             },
    //             {
    //               text: total_geral,
    //               alignment: "right",
    //               fontSize: 8,
    //             },
    //           ],
    //         ],
    //       },
    //       layout: {
    //         // hLineWidth: function(i, node) {
    //         //   return i <= 1 || i === node.table.body.length ? 1 : 0;
    //         // },
    //         hLineColor: function (i, node) {
    //           return i === 0 || i === node.table.body.length
    //             ? "#666666"
    //             : "#666666";
    //         },
    //         vLineColor: function (i, node) {
    //           return i === 0 || i === node.table.widths.length
    //             ? "#666666"
    //             : "#666666";
    //         },
    //         fillColor: function (rowIndex, node, columnIndex) {
    //           return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
    //         },
    //       },
    //     },
    //   ],
    // };

    // let obs = {
    //   stack: [
    //     {
    //       text: i18n.tc("global.observacao").toUpperCase() + ": ",
    //       fontSize: 9,
    //       bold: true,
    //       // margin: [left, top, right, bottom]
    //       margin: [0, 20, 0, 0],
    //     },
    //     {
    //       text: ordem_servico.obs,
    //       fontSize: 8,
    //     },
    //   ],
    // };

    // let forma_pagamento = {
    //   stack: [
    //     {
    //       text: i18n.tc("global.formapagamento").toUpperCase() + ": ",
    //       fontSize: 9,
    //       bold: true,
    //       // margin: [left, top, right, bottom]
    //       margin: [0, 20, 0, 0],
    //     },
    //     {
    //       text: ordem_servico.forma_pagamento,
    //       fontSize: 8,
    //     },
    //   ],
    // };

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.2,
      },
      content: [
        empresa.logo64 ? logo : "",
        ...dados,
        tabela_items,
        // legenda,
        // tabela_total,
        // ordem_servico.obs ? obs : "",
        // ordem_servico.forma_pagamento ? forma_pagamento : "",
      ],
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `${nome} as ${now}`,
                fontSize: 7,
                margin: [15, 0, 0, 0],
                width: "25%",
              },
              {
                text: "SOFTPAR - SOFTWARE A MEDIDA DO SEU NEGÓCIO",
                fontSize: 7,
                alignment: "center",
                width: "*",
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 15, 0],
                alignment: "right",
                fontSize: 7,
                width: "25%",
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
