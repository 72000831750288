import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import store from "@/store/index.js";
import { format, parseISO } from "date-fns";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";

export async function generatePdf(empresa, ordem_servico_id) {
  const ordem_servico = await fetchOrdemServico(ordem_servico_id);
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.usuario.name;

    let logo = {
      image: empresa.logo64,
      width: 150,
      alignment: "center",
      margin: [0, 0, 0, 25],
    };

    let dados = [
      {
        // margin: [left, top, right, bottom]
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: i18n.tc("global.cliente").toUpperCase(),
                    alignment: "left",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 2],
                  },
                  {
                    stack: ordem_servico.cliente_id
                      ? [
                          {
                            text:
                              ordem_servico.cliente_nome +
                              " - " +
                              ordem_servico.cliente_doc,
                            fontSize: 8,
                            margin: [0, 0, 0, 5],
                          },

                          {
                            text: ordem_servico.cliente_telefone
                              ? [
                                  { text: "Telefone: ", bold: true },
                                  {
                                    text: ordem_servico.cliente_telefone,
                                    fontSize: 8,
                                  },
                                ]
                              : "",
                            fontSize: 8,
                          },
                          {
                            text: ordem_servico.cliente_celular
                              ? [
                                  {
                                    text: i18n.tc("global.celular") + ": ",
                                    bold: true,
                                  },
                                  {
                                    text: ordem_servico.cliente_celular,
                                    fontSize: 8,
                                  },
                                ]
                              : "",
                            fontSize: 8,
                          },
                          {
                            text: ordem_servico.endereco_cliente
                              ? [
                                  {
                                    text: i18n.tc("global.endereco") + ": ",
                                    bold: true,
                                  },
                                  {
                                    text: `${ordem_servico.endereco_cliente.rua}, ${ordem_servico.endereco_cliente.numero} - ${ordem_servico.endereco_cliente.bairro}`,
                                    fontSize: 8,
                                  },
                                ]
                              : "",
                            fontSize: 8,
                          },
                          {
                            text: ordem_servico.cliente_email
                              ? [
                                  { text: "Email: ", bold: true },
                                  {
                                    text: ordem_servico.cliente_email,
                                    fontSize: 8,
                                  },
                                ]
                              : "",
                            fontSize: 8,
                          },
                        ]
                      : [],
                  },
                ],
              },
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: `${i18n
                      .tc("global.ordensServicos")
                      .toUpperCase()} Nº.: ${ordem_servico.id}`,
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text:
                      i18n.tc("global.criadoPor").toUpperCase() +
                      " : " +
                      ordem_servico.criado_por +
                      " - " +
                      format(parseISO(ordem_servico.data_criada), "dd/MM/yyyy"),
                    alignment: "right",
                    fontSize: 8,
                  },
                  {
                    text: `${i18n
                      .tc("global.previsaoInicio")
                      .toUpperCase()} : ${
                      ordem_servico.previsao_inicio
                        ? format(
                            parseISO(ordem_servico.previsao_inicio),
                            "dd/MM/yyyy"
                          )
                        : ""
                    }`,
                    alignment: "right",
                    fontSize: 8,
                  },
                ],
              },
            ],
            [
              {
                margin: [0,1,0,1],
                colSpan: 2,
                stack: [
                  {
                    text:
                      ordem_servico.mao_de_obra &&
                      ordem_servico.mao_de_obra.length > 0
                        ? { text: "MÃO DE OBRA: ", bold: true }
                        : "",
                    fontSize: 9,
                  },
                  {
                    text:
                      ordem_servico.mao_de_obra &&
                      ordem_servico.mao_de_obra.length > 0
                        ? ordem_servico.mao_de_obra[0].descricao
                        : "",
                    fontSize: 9,
                  },
                  
                ],
              },
            ],
            [
              {
                margin: [0,1,0,1],
                colSpan: 2,
                stack: [
                  {
                    text:
                    ordem_servico.servico_prestado 
                        ? { text: "SERVIÇO PRESTADO:", bold: true }
                        : "",
                    fontSize: 9,
                  },
                  {
                    text:
                    ordem_servico.servico_prestado
                        ? ordem_servico.servico_prestado
                        : "",
                    fontSize: 9,
                  },
                  
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      },
    ];

    // let tabela_observacoes = {
    //   table: {
    //     widths: ["*"],
    //     headerRows: 1,
    //     body: [
    //       [
    //         {
    //           text: 'Observações'.toUpperCase(),
    //           fontSize: 8,
    //           fillColor: "#CFD8DC",
    //         },
    //       ],
    //     ],
    //   },
    //   layout: {
    //     hLineWidth: function (i, node) {
    //       return i <= 1 || i === node.table.body.length ? 1 : 0;
    //     },
    //     hLineColor: function (i, node) {
    //       return i === 0 || i === node.table.body.length ? "#666666" : "#666666";
    //     },
    //     vLineColor: function (i, node) {
    //       return i === 0 || i === node.table.widths.length ? "#666666" : "#666666";
    //     },
    //     fillColor: function (rowIndex, node, columnIndex) {
    //       return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
    //     },
    //   },
    // };

    // let observacao_itens = ordem_servico.observacoes;

    // observacao_itens.forEach((item) => {
    //   let obj = { ...item };
    //   let bodyLineFontSize = 8;
    //   let bodyLine = [
    //     {
    //       text: obj.observacao,
    //       fontSize: bodyLineFontSize,
    //     },
    //   ];
    //   tabela_observacoes.table.body.push(bodyLine);
    // });

    let tabela_items = {
      table: {
        widths: ["*", "auto"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: i18n.tc("global.produto").toUpperCase() + "(s)",
              fontSize: 8,
              fillColor: "#CFD8DC",
            },
            {
              text: "QTDE",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let produtos_items = [];
    ordem_servico.produtos.forEach((produto) => {
      if (produto.tipo == "ITEM") {
        produto.produtos_kit = [];
        produtos_items.push(produto);
      }
    });
    ordem_servico.produtos.forEach((produto) => {
      if (produto.tipo == "SUB-ITEM") {
        let index = produtos_items.findIndex((produto_array) => {
          return produto_array.id === produto.produto_pai_id;
        });
        produto.index_produto = index;

        produtos_items[index].produtos_kit.push(produto);
      }
    });

    produtos_items.forEach((produto) => {
      if (produto.produtos_kit.length) {
        produto.kit = true;
      }
    });

    produtos_items.forEach((item) => {
      let obj = { ...item };

      let bodyLineFontSize = 8;

      let bodyLine = [
        {
          text: obj.descricao,
          bold: obj.kit ? true : false,
          fontSize: bodyLineFontSize,
        },
        {
          text: obj.qtde,
          bold: obj.kit ? true : false,
          fontSize: bodyLineFontSize,
          alignment: "center",
        },
      ];

      tabela_items.table.body.push(bodyLine);
      if (obj.kit) {
        obj.produtos_kit.forEach((item) => {
          let obj_kit = { ...item };
          let bodyLineKit = [
            {
              text: "|__   " + obj_kit.descricao,

              fontSize: bodyLineFontSize,
            },
            {
              text: obj_kit.qtde,
              fontSize: bodyLineFontSize,
              alignment: "center",
            },
          ];
          tabela_items.table.body.push(bodyLineKit);
        });
      }
    });

    let tabela_prestadores = {
      table: {
        widths: ["*"],
        headerRows: 1,
        body: [
          [
            {
              text: i18n.tc("global.prestador").toUpperCase() + "(es)",
              fontSize: 8,
              fillColor: "#CFD8DC",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let prestadores_items = ordem_servico.prestadores;

    prestadores_items.forEach((item) => {
      let obj = { ...item };
      let bodyLineFontSize = 8;
      let bodyLine = [
        {
          text: obj.fornecedor_nome,
          fontSize: bodyLineFontSize,
        },
      ];
      tabela_prestadores.table.body.push(bodyLine);
    });

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.2,
      },
      content: [
        empresa.logo64 ? logo : "",
        ...dados,
        tabela_items,
        { text: "", margin: [0, 10] },
        tabela_prestadores,
        {
          text: [{ text: "OBSERVAÇÕES: ", bold: true, fontSize: 9 }],
          margin: [0, 10, 0, 4],
        },
        {
          text: [
            `_____________________________________________________________________________________________________________________________\n`,
            `_____________________________________________________________________________________________________________________________\n`,
          ],
          bold: false,
          fontSize: 10,
        },
        {
          margin: [0, 100, 0, 0],
          columns: [
            {
              text: [
                `………………………………………………\n`,
                `${ordem_servico.cliente_nome}\n`,
                `RUC: ${ordem_servico.cliente_doc}\n`,
              ],
              alignment: `center`,
              fontSize: 11,
              bold: true,
            },
          ],
        },
      ],
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `${nome} as ${now}`,
                fontSize: 7,
                margin: [15, 0, 0, 0],
                width: "25%",
              },
              {
                text: "SOFTPAR - SOFTWARE A MEDIDA DO SEU NEGÓCIO",
                fontSize: 7,
                alignment: "center",
                width: "*",
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 15, 0],
                alignment: "right",
                fontSize: 7,
                width: "25%",
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
